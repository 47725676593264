import React from "react";
import MaskedInput from "react-text-mask";

const MaskedCep = (props) => {
  const { inputRef, ...other } = props;

  const cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

  return (
    <MaskedInput
      {...other}
      mask={cepMask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

export default MaskedCep;
