import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { Box, Grid } from "@mui/material";
import useFetch from "use-http";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Select/Select";
import Snackbar from "../../../components/Snackbar/Snackbar";

const EnrollmentModal = ({ isOpen, handleClose, student }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { post, get } = useFetch();

  const [classrooms, setClassrooms] = useState([]);
  const [SnackbarOpen, setSnackbarOpen] = useState(false);

  const [selectedClassroom, setSelectedClassroom] = useState(null);

  const handleConfirm = async () => {
    try {
      setSnackbarOpen(true);
      handleSnackbarOpen();
      await post("/enrollment/create", {
        student: student,
        classroom: selectedClassroom,
      });
      handleClose();
    } catch (error) {}
  };

  const handleCancel = () => {
    handleClose();
  };

  async function getClassrooms() {
    const classrooms = await get("/classrooms");
    console.log(classrooms);
    var classroomOptions = classrooms.map((classroom: any) => {
      return {
        label: classroom.name,
        value: classroom.id,
      };
    });
    setClassrooms(classroomOptions);
    console.log(classroomOptions);
  }

  useEffect(() => {
    getClassrooms();
  }, []);

  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (openSnackbar) {
      const timer = setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  });

  return (
    <Box>
      {classrooms?.length > 0 ? (
        <Modal
          open={isOpen}
          onClose={handleClose}
          title={"Realizar Matricula"}
          children={
            <form>
              <Snackbar
                open={openSnackbar}
                message="Aluno matriculado com sucesso!"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setOpenSnackbar(false)}
              />
              <Select
                options={classrooms}
                label={"Escolha a Turma"}
                sx={{ margin: "12px 0px" }}
                value={selectedClassroom}
                onChange={(e) => {
                  setSelectedClassroom(e.target.value);
                }}
              ></Select>
              <Grid display={"flex"} justifyContent={"space-between"}>
                <Button onClick={handleConfirm} color={"success"}>
                  Confirmar
                </Button>
                <Button onClick={handleCancel} color={"primary"}>
                  Cancelar
                </Button>
              </Grid>
            </form>
          }
        />
      ) : (
        <Snackbar
          open={isOpen}
          message="Cadastre uma sala de aula antes de realizar a matricula."
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          severity={"error"}
          onClose={handleClose}
        />
      )}
    </Box>
  );
};

export default EnrollmentModal;
