import "./style.css";

import {
  Typography,
  Box,
  Paper,
  List,
  Link,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import useFetch from "use-http";
import CreateClassModal from "./CreateClassModal";
import { useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
require("moment/locale/pt-br");

const localizer = momentLocalizer(moment);

const ClassroomDetails = ({ details, schedule }) => {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState<any>([]);

  const { get } = useFetch();

  useEffect(() => {
    try {
      get(`/teachers`).then(setTeachers);
    } catch (error) {
      console.error("error fetching teachers", error);
    }
  }, []);

  function setTimeToDate(date, time) {
    const [hours, minutes] = time.split(":");

    const newDate = new Date(date);
    newDate.setHours(Number(hours));
    newDate.setMinutes(Number(minutes));
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
  }

  const events = details?.lessons?.map((lesson) => ({
    id: lesson.id,
    title: `${lesson.subject.name} - ${lesson.teacher.fullName}`,
    start: setTimeToDate(lesson.date, lesson.startTime),
    end: setTimeToDate(lesson.date, lesson.endTime),
    allDay: false,
  }));

  // const events = [
  //   {
  //     id: 1,
  //     title: "TEst",
  //     start: new Date(2023, 7, 25, 0, 0, 0),
  //     end: new Date(2023, 7, 25, 1, 0, 0),
  //     allDay: false,
  //   },
  // ];

  console.log("events", events);

  const now = new Date();
  const nextLesson = schedule.find((lesson) => lesson.start > now);

  const formatDate = (date) => {
    date = new Date(date);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const { name: nextLessonName } = nextLesson || {};

  return (
    <Box display={"flex"}>
      <Box width="300px" p={2}>
        <Paper elevation={3}>
          <Box p={2}>
            <Typography variant="h6">Proximas Aulas: </Typography>
            {details?.lessons?.map((lesson) => {
              return (
                <Box mt={2} p={2} sx={{ backgroundColor: "lightgrey" }}>
                  <Typography variant="body1">
                    {formatDate(lesson.date)} - {lesson.startTime} -{" "}
                    {lesson.endTime} {lesson.subject.name} -{" "}
                    {lesson.teacher.fullName}
                  </Typography>
                </Box>
              );
            })}
            <Typography variant="body1">{nextLessonName}</Typography>
          </Box>
          <Box p={2}>
            <CreateClassModal />
          </Box>
        </Paper>

        <Box mt={2}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Professores: </Typography>
            </Box>
            {teachers && (
              <Box pl={4}>
                <List>
                  {teachers.map((teacher) => (
                    <ListItem key={teacher.id} disablePadding>
                      <ListItemText
                        primary={
                          <Link
                            href={`/professor/${teacher.id}`}
                            color="inherit"
                          >
                            {teacher.fullName}
                          </Link>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
      <Box flex={1} p={2}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          view="agenda"
          views={["agenda"]}
          onSelectEvent={(event: any) => navigate(`/aula/${event.id}`)}
          messages={{
            today: "Hoje",
            previous: "Anterior",
            next: "Próximo",
            week: "Semana",
            agenda: "Agenda",
            date: "Data",
            time: "Hora",
            event: "Evento",
            noEventsInRange: "Não há eventos neste período",
            showMore: (total) => `+ Ver mais (${total})`,
          }}
        />
      </Box>
    </Box>
  );
};

export default ClassroomDetails;
