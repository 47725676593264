import React from "react";
import MaskedInput from "react-text-mask";

const MaskedCPF = (props) => {
  const { inputRef, ...other } = props;

  const cpfMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  return (
    <MaskedInput
      {...other}
      mask={cpfMask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

export default MaskedCPF;
