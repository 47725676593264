import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useRevalidator } from "react-router-dom";
import GradeFormModal from "./GradeFormModal/GradeFormModal";
import { useFetch } from "use-http";
import Loading from "../../../components/Loading/Loading";
import DataGridTable from "../../../components/DataGrid/DataGrid";

const GradesList = () => {
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [grades, setGrades] = useState(undefined);

  const revalidator = useRevalidator();

  const { get } = useFetch();

  async function getGrades() {
    const gradeData = await get("/grades");
    setGrades(gradeData);
  }

  useEffect(() => {
    getGrades();
  }, []);

  const columns = [{ field: "name", headerName: "Nome" }];
  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          setSelectedGrade(null);
          setFormModalOpen(true);
        }}
      >
        Nova Série
      </Button>
      {grades === undefined ? (
        <Loading />
      ) : (
        <DataGridTable rows={grades} columns={columns} actions={undefined} />
      )}
      <GradeFormModal
        open={formModalOpen}
        grade={selectedGrade}
        onClose={() => setFormModalOpen(false)}
        revalidator={revalidator}
      />
    </Box>
  );
};

export default GradesList;
