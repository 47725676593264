import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Tabs, Tab } from "@mui/material";
import ClassroomDetails from "./ClassroomDetails";
import ClassroomSchedule from "./ClassroomSchedule";
import StudentList from "./StudentList";
import { useFetch } from "use-http";
import { useParams } from "react-router-dom";

const ClassroomPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [classroomDetails, setClassroomDetails] = useState<any>({} as any);

  const { get } = useFetch();

  const { id } = useParams();

  useEffect(() => {
    try {
      get(`/classrooms/${id}`).then((data) => setClassroomDetails(data));
    } catch (error) {
      console.error("error fetching classroom/ :id", error);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ m: 3 }}>
      <Typography variant="h4">{classroomDetails?.name}</Typography>
      <Paper sx={{ width: "100%", mt: 2, mb: 3 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Detalhes" />
          <Tab label="Horarios" />
          <Tab label="Alunos" />
        </Tabs>
      </Paper>
      {tabValue === 0 && (
        <ClassroomDetails details={classroomDetails} schedule={[]} />
      )}
      {tabValue === 1 && <ClassroomSchedule />}
      {tabValue === 2 && (
        <StudentList enrollments={classroomDetails.enrollments} />
      )}
      {/* {tabValue === 3 && <TeacherList />} */}
    </Box>
  );
};

export default ClassroomPage;
