import { Box } from "@mui/material";
import Card from "../../../../components/Card/Card";
import DataTable from "../../../../components/Table/Table";

const EnrollmentTable = () => {
  const columns = [
    { field: "enrollment", headerName: "Matrícula" },
    { field: "classroom", headerName: "Turma" },
    { field: "status", headerName: "Situação" },
  ];

  const row = [{ id: 1, enrollment: "123", classroom: "1A", status: "Ativo" }];

  return (
    <Box>
      <Card
        contentStyle={{ marginTop: -10 }}
        title={"Matrículas"}
        content={
          <>
            <DataTable rows={row} columns={columns} actions={undefined} />
          </>
        }
        actions={null}
      ></Card>
    </Box>
  );
};

export default EnrollmentTable;
