import { Grid, TextField } from "@mui/material";
import Input from "../../../../components/Input/input";
import MaskedCPF from "../../../../components/MaskedCPF/MaskedCPF";
import MaskedPhone from "../../../../components/MaskedPhone/MaskedPhone";

const GuardianForm = ({ register }) => {
  return (
    <>
      <Grid item xs={12}>
        <Input
          label="Nome do responsável"
          fullWidth
          margin="normal"
          register={register("guardian.name")}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="CPF do responsável"
          fullWidth
          margin="normal"
          register={register("guardian.cpf")}
          InputProps={{ inputComponent: MaskedCPF }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Telefone do responsável"
          fullWidth
          margin="normal"
          register={register("guardian.phone")}
          InputProps={{ inputComponent: MaskedPhone }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Email do responsável"
          fullWidth
          margin="normal"
          register={register("guardian.email")}
        />
      </Grid>
    </>
  );
};

export default GuardianForm;
