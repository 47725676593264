import React, { useState } from "react";
import CustomAppBar from "../../components/AppBar/AppBar";
import CustomDrawer from "../../components/Drawer/Drawer";
import styled from "@emotion/styled";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "../../theme";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/AuthContext";

const PageWrapper = styled.div(() => ({}));

const MainContent = styled.main(() => ({
  flexGrow: 1,
  padding: "24px",
  marginTop: "0px",
  backgroundColor: "white",
}));

const BasePage = ({ children }: any) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { user, loading } = useAuthContext();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const theme = createTheme();

  return loading ? (
    <div>Loading...</div>
  ) : user != null ? (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <CustomDrawer isOpen={drawerOpen} />
        <CustomAppBar
          onDrawerButtonClick={toggleDrawer}
          isDrawerOpen={drawerOpen}
        />
        <MainContent
          style={{
            marginLeft: drawerOpen ? "280px" : 0,
          }}
        >
          <div>{children}</div>
        </MainContent>
      </PageWrapper>
    </ThemeProvider>
  ) : (
    <Navigate to="/login" />
  );
};

export default BasePage;
