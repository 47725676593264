import { useEffect, useState } from "react";
import { Box, TextField, Button, Grid } from "@mui/material";
import { useFetch } from "use-http";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const daysOfWeek = [
  {
    value: 1,
    label: "Segunda",
  },
  {
    value: 2,
    label: "Terça",
  },
  {
    value: 3,
    label: "Quarta",
  },
  {
    value: 4,
    label: "Quinta",
  },
  {
    value: 5,
    label: "Sexta",
  },
  {
    value: 6,
    label: "Sabado",
  },
  {
    value: 0,
    label: "Domingo",
  },
];

const ClassroomSchedule = () => {
  const [schedule, setSchedule] = useState<any>([]);

  const [subjects, setSubjects] = useState<any>([]);
  const [teachers, setTeachers] = useState<any>([]);

  const { get, post } = useFetch();

  const { id } = useParams();

  useEffect(() => {
    try {
      get("/subjects").then((data) => setSubjects(data));
      get("/teachers").then((data) => setTeachers(data));
      get(`/schedule/${id}`).then((data) => setSchedule(data));
    } catch (error) {
      console.error("Error fetching subjects, teachers and schedule", error);
    }
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dayOfWeek: "1",
      startTime: "",
      endTime: "",
      subject: "",
      teacher: "",
      classroom: id,
    },
  });

  const createSchedule = async (body) => {
    post("/schedule/create", {
      body,
    }).then((data) => {
      setSchedule(data);
    });
  };

  return (
    <Box sx={{ m: 3 }}>
      {schedule?.map((event) => {
        return (
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <TextField
                  select
                  label="Dia"
                  fullWidth
                  value={event.dayOfWeek}
                  SelectProps={{ native: true }}
                  disabled
                >
                  {daysOfWeek.map((day) => (
                    <option key={day.value} value={day.value}>
                      {day.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6} sm={2}>
                <TextField
                  label="Inicio"
                  type="time"
                  fullWidth
                  value={event.startTime}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                  disabled
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <TextField
                  label="Termino"
                  type="time"
                  fullWidth
                  value={event.endTime}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <TextField
                  select
                  label="Matéria"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: true }}
                  value={event.subject.id}
                  disabled
                >
                  <option></option>
                  {subjects.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={2}>
                <TextField
                  select
                  label="Professor"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: true }}
                  value={event.teacher.id}
                  disabled
                >
                  <option></option>

                  {teachers.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.fullName}
                    </option>
                  ))}
                </TextField>
              </Grid>

              {/* <Grid item xs={12} sm={1}>
              <IconButton onClick={() => handleRemoveLesson(index)}>
                <RemoveCircleOutline />
              </IconButton>
            </Grid> */}
            </Grid>
          </Box>
        );
      })}
      <form onSubmit={handleSubmit(createSchedule)}>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <TextField
                select
                label="Dia"
                fullWidth
                {...register("dayOfWeek")}
                SelectProps={{ native: true }}
              >
                {daysOfWeek.map((day) => (
                  <option key={day.value} value={day.value}>
                    {day.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6} sm={2}>
              <TextField
                label="Inicio"
                type="time"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
                {...register("startTime")}
              />
            </Grid>

            <Grid item xs={6} sm={2}>
              <TextField
                label="Termino"
                type="time"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
                {...register("endTime")}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                select
                label="Matéria"
                fullWidth
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                {...register("subject")}
              >
                <option></option>
                {subjects.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                select
                label="Professor"
                fullWidth
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                {...register("teacher")}
              >
                <option></option>

                {teachers.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.fullName}
                  </option>
                ))}
              </TextField>
            </Grid>

            {/* <Grid item xs={12} sm={1}>
              <IconButton onClick={() => handleRemoveLesson(index)}>
                <RemoveCircleOutline />
              </IconButton>
            </Grid> */}
          </Grid>
        </Box>
        <Button variant="contained" color="success" type="submit">
          Salvar Horario
        </Button>
      </form>
    </Box>
  );
};

export default ClassroomSchedule;
