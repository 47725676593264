import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import MuiCard from "../../../../components/Card/Card";
import useFetch from "use-http";
import { useParams } from "react-router-dom";
import student from "../../../../interfaces/student";
import StudentForm from "../Register/StudentForm";
import GuardianForm from "../Register/GuardianForm";

const EditRegister = () => {
  const [studentData, setStudentData] = useState<student | null>(null);
  const [avatarImage, setAvatarImage] = useState<File | null>(null);
  const { register, handleSubmit, setValue, control } = useForm();
  const { id } = useParams();
  const { get } = useFetch();

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAvatarImage(event.target.files[0]);
    }
  };

  const fetchStudentData = async () => {
    const student = await get(`/students/${id}`);
    setStudentData(student);
  };

  useEffect(() => {
    fetchStudentData();
  }, []);

  useEffect(() => {
    if (studentData) {
      Object.keys(studentData).forEach((key) => {
        console.log(key, studentData[key]);
        setValue(key, studentData[key]);
      });
    }
  }, [studentData, setValue]);

  const onSubmit = (data) => {
    console.log(data);
  };

  return studentData ? (
    <Box>
      <Typography variant="h4" mb={3}>
        Atualizar cadastro
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MuiCard
          title={"Editar aluno"}
          content={
            <>
              <Grid container spacing={2}>
                <StudentForm
                  register={register}
                  handleAvatarChange={handleAvatarChange}
                  avatarImage={avatarImage}
                  control={control}
                />
              </Grid>
              <hr />
              <h3>Dados do Responsável</h3>
              <GuardianForm register={register} />
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Atualizar
                </Button>
              </Grid>
            </>
          }
          actions={undefined}
        />
      </form>
    </Box>
  ) : (
    <></>
  );
};

export default EditRegister;
