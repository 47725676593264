import React from "react";
import { Snackbar as MuiSnackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Stack } from "@mui/material";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({
  open,
  onClose,
  message,
  severity = "success",
  autoHideDuration = 2000,
  ...rest
}: any) => {
  return (
    <MuiSnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      {...rest}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
