import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
} from "@mui/material";

const Select = ({ label, value, onChange, options, ...rest }) => {
  console.log("value", value);
  return (
    <FormControl fullWidth variant="outlined" {...rest}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect label={label} value={value} onChange={onChange}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
