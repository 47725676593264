import React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import loginBG from "../../assets/alunos-milenio.jpeg";
import CssBaseline from "@mui/material/CssBaseline";
import logoMilenio from "../../assets/logo-milenioPNG.png";

import Paper from "@mui/material/Paper";

import LoginForm from "./LoginForm/Form";
import { useAuthContext } from "../../hooks/AuthContext";
import { Navigate } from "react-router-dom";

const Login = () => {
  const { user, logout, loading } = useAuthContext();

  return user == null ? (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${loginBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "start",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        square
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ width: 200, height: 200, m: 1, bgcolor: "white" }}>
            <img src={logoMilenio} alt="logo milenio" width={200} />
          </Avatar>
          <LoginForm />
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Navigate to="/" />
  );
};

export default Login;
