import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth, useUser } from "reactfire";

const AuthContext = createContext({} as any);

export const useAuthContext = () => useContext(AuthContext);

export const ClassfyAuthProvider = ({ children }) => {
  const auth = useAuth();

  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      console.log(u);
      setLoading(false);
      setUser(u);
    });
  }, []);

  const [loading, setLoading] = useState(true); // Add a loading state

  // Function to login
  const login = async (email, password) => {
    // await auth.signInWithEmailAndPassword(email, password);
  };

  // Function to logout
  const logout = async () => {
    await auth.signOut();
  };

  // Function to register
  const register = async (email, password) => {
    // await auth.createUserWithEmailAndPassword(email, password);
  };

  const value = {
    user,
    login,
    logout,
    register,
    loading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
