// pages/Admin/Teachers/TeachersList.js

import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import DataTable from "../../../components/Table/Table";
import { useNavigate } from "react-router";
import useFetch from "use-http";
import Teachers from "../../../interfaces/teachers";

const TeachersList = () => {
  const navigate = useNavigate();

  const [teachers, setTeachers] = useState<Teachers[]>([]);

  const { get } = useFetch();

  useEffect(() => {
    async function getTeachers() {
      try {
        const teacherData = await get("/teachers");
        console.log(teacherData);

        if (Array.isArray(teacherData)) {
          setTeachers(teacherData);
        }
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    }
    getTeachers();
  }, [get]);

  const columns = [
    { field: "fullName", headerName: "Name" },
    { field: "email", headerName: "Email" },
  ];

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          navigate(`/professores/novo`);
        }}
      >
        Novo Professor
      </Button>
      <DataTable rows={teachers} columns={columns} actions={undefined} />
    </Box>
  );
};

export default TeachersList;
