import React, { useState, useEffect } from "react";
import { Box, IconButton, colors } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import MuiCard from "../../../../components/Card/Card";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import DataGridTable from "../../../../components/DataGrid/DataGrid";
import Loading from "../../../../components/Loading/Loading";
import { GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import FeedIcon from "@mui/icons-material/Feed";
import EnrollmentModal from "../../Enrollment/EnrollmentModal";
import Button from "../../../../components/Button/Button";
import useFetch from "use-http";

interface Enrollment {
  studentId: string;
  status: string;
  // outras propriedades da matrícula
}

const StudentsList = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState(undefined);
  const [selected, setSelected] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { get } = useFetch();

  const fetchStudents = async () => {
    const student = await get(`/students`);

    setStudents(student);
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const EditRegister = (id) => {
    navigate(`/editar/${id}`);
  };

  const StatusHash = {
    interested: "Interessado",
    enrolled: "Matriculado",
    locked: "Trancado",
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Nome",
      flex: 1.2,
    },
    {
      field: "dateOfBirth",
      headerName: "Data de nascimento",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Gênero",
      width: 120,
    },
    {
      field: "guardian",
      headerName: "Nome do responsável",
      flex: 1,
      renderCell: (params) => <span>{params.row.guardian?.name}</span>,
    },
    {
      field: "actions",
      headerName: "Ações",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton onClick={() => navigate(`${params.row.id}`)}>
          <FeedIcon />
        </IconButton>
      ),
    },
    {
      field: "edit",
      headerName: "Editar",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton onClick={() => EditRegister(params.row.id)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "Matricular",
      headerName: "Turma",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) =>
        params.row.status == "enrolled" ? (
          <Link to={`/turmas/${params.row.enrollments[0].classroom.id}`}>
            {params.row.enrollments[0].classroom.name}
          </Link>
        ) : (
          <Button
            color="success"
            onClick={() => {
              setSelected(params.row.id);
              setIsModalOpen(true);
            }}
          >
            Matricular
          </Button>
        ),
      // <IconButton
      //   onClick={() => {
      //     setSelected(params.row.id);
      //     setIsModalOpen(true);
      //   }}
      // >
      //   {" "}
      //   <AddCircleIcon />
      // </IconButton>
    },
  ];

  return (
    <Box>
      <Button
        variant="contained"
        color={"primary"}
        startIcon={<AddIcon />}
        onClick={() => {
          navigate("/cadastro");
        }}
      >
        Novo Aluno
      </Button>
      {students === undefined ? (
        <Loading />
      ) : (
        <React.Fragment>
          <MuiCard
            title={"Alunos"}
            content={
              <SimpleBar style={{ maxHeight: 1000 }}>
                <DataGridTable rows={students} columns={columns} />
              </SimpleBar>
            }
            actions={undefined}
          />
          <EnrollmentModal
            isOpen={isModalOpen}
            handleClose={() => {
              setIsModalOpen(false);
              fetchStudents();
            }}
            student={selected}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default StudentsList;
