import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

const DataTable = ({ rows, columns, actions }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                style={{
                  backgroundColor: "white",
                  // padding: 5,
                  fontWeight: "bold",
                  // textAlign: "center",
                  textTransform: "none",
                }}
                key={column.field}
              >
                {column.headerName}
              </TableCell>
            ))}
            {actions && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows !== undefined &&
            rows?.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell
                    key={`${row.id}-${column.field}`}
                    // style={{ textAlign: "center" }}
                  >
                    {row[column.field]}
                  </TableCell>
                ))}
                {actions && (
                  <TableCell>
                    {actions.onEdit && (
                      <IconButton
                        onClick={() => {
                          actions.onEdit(row);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {actions.onDelete && (
                      <IconButton
                        onClick={() => {
                          actions.onDelete(row.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
