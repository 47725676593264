import { useForm } from "react-hook-form";
import {
  Button,
  Container,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import Input from "../../../../components/Input/input";
import useFetch from "use-http";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreateUser() {
  const { get, post } = useFetch();

  const { register, handleSubmit, watch, setValue } = useForm();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    post("/auth/create-user", data).then((response) => {
      navigate("/usuarios");
    });
  };

  const [roles, setRoles] = useState<any>([]);
  const [teachers, setTeachers] = useState<any>([]);

  useEffect(() => {
    get("/roles").then((roles) => setRoles(roles));
    get("/teachers").then((teachers) => setTeachers(teachers));
  }, []);

  const role = watch("role", "");

  useEffect(() => {
    if (role !== "teacher") {
      setValue("teacher", "");
    }
  }, [role]);

  return (
    <Container component="main">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Box display={"flex"} justifyContent={"center"} mb={4}>
          <Typography component="h1" variant="h5">
            Criar Usuário
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Input
                  label="Nome Completo"
                  id="name"
                  register={register("name", { required: true })}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Input
                  label="Email"
                  id="email"
                  register={register("email", { required: true })}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Input
                  label="Senha"
                  id="password"
                  type="password"
                  register={register("password", { required: true })}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Input
                  label="Confirmar Senha"
                  id="confirmPassword"
                  type="password"
                  register={register("confirmPassword", { required: true })}
                />
              </FormControl>
            </Grid>

            {roles != undefined && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="role">Função</InputLabel>
                  <Select id="role" {...register("role", { required: true })}>
                    {roles.map((role) => (
                      <MenuItem key={role.name} value={role.name}>
                        {role.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {role === "teacher" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="linkedTeacher">
                    Escolha um Professor
                  </InputLabel>
                  <Select
                    id="linkedTeacher"
                    {...register("teacher", { required: true })}
                  >
                    {teachers.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Criar Usuário
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
