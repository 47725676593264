import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import Card from "../../../../components/Card/Card";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import { useEffect, useState } from "react";
import { useFetch } from "use-http";
import { TrySharp } from "@mui/icons-material";

const StudentInfoCard = ({ student }) => {
  const studentInfoFields = [
    { title: "Nome completo", key: "fullName" },
    { title: "Data de nascimento", key: "dateOfBirth" },
    { title: "Gênero", key: "gender" },
    { title: "Status", key: "status" },
  ];

  console.log(student);

  const studentInfoRow = (title: string, content: string) => {
    return (
      <Box>
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="subtitle2" style={{ margin: 0 }}>
            {title}
          </Typography>
          <Typography variant="body2" style={{ margin: 0 }}>
            {content}
          </Typography>
        </ListItem>
        <Divider />
      </Box>
    );
  };

  return (
    <Box>
      {student == undefined ? (
        <Loading />
      ) : (
        <Card
          contentStyle={{ padding: 8 }}
          title={"Informações Básicas"}
          content={
            <List>
              {studentInfoFields.map(
                (field) =>
                  student[field.key] &&
                  studentInfoRow(field.title, student[field.key])
              )}
            </List>
          }
          actions={undefined}
        />
      )}
    </Box>
  );
};

export default StudentInfoCard;
