import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import useFetch from "use-http";
import { useParams } from "react-router-dom";

function LessonDetails() {
  const [classDiary, setClassDiary] = useState("");
  const [attendance, setAttendance] = useState<any>([]);

  const [lesson, setLesson] = useState<any>({});

  const { id } = useParams();

  const { get, post } = useFetch();

  useEffect(() => {
    get(`/lessons/${id}`).then((res) => {
      setLesson(res);
      setAttendance(
        res?.classroom?.enrollments?.map((enrollment) => ({
          id: enrollment.id,
          name: enrollment.student.fullName,
          attended: false,
        }))
      );
    });
  }, [get]);

  const handleAttendance = (id) => {
    console.log(id);
    const att = attendance.map((student) =>
      student.id === id ? { ...student, attended: !student.attended } : student
    );
    console.log(att);

    setAttendance(att);

    console.log(attendance);
  };

  const handleDiaryChange = (event) => {
    setClassDiary(event.target.value);
  };

  const handleFinish = async () => {
    await post(`/lessons/${id}/finish`, {
      classDiary,
      attendance: attendance.map((student) => ({
        studentId: student.id,
        attended: student.attended,
      })),
    });
    console.log("Attendance: ", attendance);
    console.log("Class Diary: ", classDiary);
  };

  const handleCancel = () => {
    setClassDiary("");
    setAttendance([]);
  };

  const formatData = (date) => {
    const dateObject = new Date(date);
    return `${dateObject.getDate()}/${
      dateObject.getMonth() + 1
    }/${dateObject.getFullYear()}`;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <h1>
          Aula de {lesson?.subject?.name} - {lesson?.teacher?.fullName}{" "}
        </h1>
        <Typography variant="body1"> {formatData(lesson?.date)} </Typography>
        <h2>Chamada</h2>
        <List>
          {lesson?.classroom?.enrollments?.map((enrollment) => (
            <ListItem key={enrollment.id}>
              <ListItemText primary={enrollment.student.fullName} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={() => handleAttendance(enrollment.id)}
                  checked={enrollment.attended}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <h2>Diario de Classe</h2>
        <TextField
          label="O que foi ensinado hoje ? "
          variant="outlined"
          multiline
          rows={4}
          value={classDiary}
          onChange={handleDiaryChange}
          fullWidth
        />
        <Box mt={3} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancel}
            sx={{ mr: 2 }}
          >
            Cancelar Aula
          </Button>
          <Button variant="contained" color="success" onClick={handleFinish}>
            Finalizar Aula
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LessonDetails;
