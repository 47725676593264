import { useEffect, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon, Visibility } from "@mui/icons-material";
import DataGridTable from "../../../../components/DataGrid/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import RegisterClassroom from "../Register/RegisterClass";
import { useFetch } from "use-http";
import ClassRoom from "../../../../interfaces/classRoom";

const ClassroomsList = () => {
  const [selectedClassrooms, setSelectedClassrooms] = useState<ClassRoom[]>([]);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const navigate = useNavigate();

  const { get } = useFetch();

  useEffect(() => {
    async function getClassrooms() {
      try {
        const classrooms = await get("/classrooms");
        if (Array.isArray(classrooms)) {
          setSelectedClassrooms(classrooms);
        }
      } catch (error) {
        console.error("Error fetching classrooms:", error);
      }
    }
    getClassrooms();
  }, [get]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Turma", width: 400 },
    {
      field: "grade",
      headerName: "Série",
      width: 400,
      valueGetter: (params) => params.row.grade?.name,
    },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: (params) => (
        <IconButton onClick={() => navigate(`/turmas/${params.row.id}`)}>
          <Visibility />
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          setFormModalOpen(true);
        }}
      >
        Nova Turma
      </Button>
      <DataGridTable rows={selectedClassrooms} columns={columns} />
      <RegisterClassroom
        open={formModalOpen}
        classroom={selectedClassrooms}
        onSubmit={undefined}
        onClose={() => setFormModalOpen(false)}
      />
    </Box>
  );
};

export default ClassroomsList;
