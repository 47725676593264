import { Box } from "@mui/material";
import Card from "../../../../components/Card/Card";
import DataTable from "../../../../components/Table/Table";
import Loading from "../../../../components/Loading/Loading";

const ParentsTable = ({ student }) => {
  const column = [
    { field: "guardianName", headerName: "Nome" },
    { field: "guardianCPF", headerName: "CPF" },
    { field: "guardianPhone", headerName: "Telefone" },
    { field: "guardianEmail", headerName: "Email" },
  ];

  return (
    <Box>
      <Card
        contentStyle={{ marginTop: -20 }}
        title={"Responsáveis"}
        content={
          <>
            {student === undefined ? (
              <Loading />
            ) : (
              <DataTable
                rows={student.parents}
                columns={column}
                actions={undefined}
              />
            )}
          </>
        }
        actions={null}
      />
    </Box>
  );
};

export default ParentsTable;
