import { Home, Settings, Help, PeopleAltOutlined } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import SubjectIcon from "@mui/icons-material/Subject";
import GradingIcon from "@mui/icons-material/Grading";

export const menuItens = [
  {
    text: "Home",
    icon: <Home />,
    path: "/",
  },
  {
    text: "Professores",
    icon: <SchoolIcon />,
    path: "/professores",
  },
  {
    text: "Alunos",
    icon: <PersonIcon />,
    path: "/alunos",
  },
  {
    text: "Turmas",
    icon: <ClassIcon />,
    path: "/turmas",
  },
  {
    text: "Materias",
    icon: <SubjectIcon />,
    path: "/materias",
  },
  {
    text: "Séries",
    icon: <GradingIcon />,
    path: "/series",
  },
  {
    text: "Usuarios",
    icon: <PeopleAltOutlined />,
    path: "/usuarios",
  },
];
