import React from "react";
import MaskedInput from "react-text-mask";

const MaskedPhone = (props) => {
  const { inputRef, ...other } = props;

  const phoneMask = [
    "(",
    /[0-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  return (
    <MaskedInput
      {...other}
      mask={phoneMask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

export default MaskedPhone;
