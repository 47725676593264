import "./styles/App.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "@fontsource/inter";

import { red } from "@mui/material/colors";

import { RouterProvider } from "react-router-dom";
import router from "./config/routes";
import { AuthProvider, useFirebaseApp } from "reactfire";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ClassfyAuthProvider, useAuthContext } from "./hooks/AuthContext";
import { Suspense, useEffect, useState } from "react";
import { CachePolicies, Provider } from "use-http";
import { SnackBarProvider } from "./hooks/SnackBarContext";

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Inter';
          }
        `,
    },
  },
  palette: {
    primary: {
      main: red[500],
    },
    secondary: {
      main: "#19857b",
    },
  },
});

function App() {
  const auth = getAuth(useFirebaseApp());
  function Loading() {
    return <div>Loading...</div>;
  }
  return (
    <AuthProvider sdk={auth}>
      <ThemeProvider theme={theme}>
        <ClassfyAuthProvider>
          <Suspense fallback={<Loading />}>
            <Classfy />
          </Suspense>
        </ClassfyAuthProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

function Classfy() {
  const { user } = useAuthContext();
  console.log(user);
  const options = {
    interceptors: {
      request: async ({ options }: any) => {
        if (user.accessToken) {
          options.headers.Authorization = `Bearer ${user.accessToken}`;
        }
        return options;
      },
      response: async ({ response }: any) => {
        return response;
      },
    },
    cachePolicy: CachePolicies.NO_CACHE,
  };
  return (
    <Provider url="http://localhost:3000" options={options}>
      <SnackBarProvider>
        <RouterProvider router={router} />
      </SnackBarProvider>
    </Provider>
  );
}

export default App;
