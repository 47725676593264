import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { useFetch } from "use-http";

const StudentList = ({ enrollments }) => {
  const [studentDetails, setStudentDetails] = useState([]);

  const { get } = useFetch();

  useEffect(() => {
    async function getStudentDetails() {
      try {
        var studentData: any = enrollments.map((student) => {
          return {
            id: student.student.id,
            fullName: student.student.fullName,
            status: student.status,
            shift: student.shift,
            // ** vamos ver quais dados colocar aqui **
          };
        });
        setStudentDetails(studentData);
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    }
    getStudentDetails();
  }, [get]);

  if (!studentDetails) {
    return null;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {studentDetails.map((student: any) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={student.id}>
            <Card>
              <CardHeader
                avatar={<Avatar />}
                title={student.fullName}
                subheader={student.status}
              />
              <CardContent>
                <Typography variant="body1" color="text.secondary">
                  {student.shift}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StudentList;
