import { useParams } from "react-router-dom";
import { Avatar, Box, Chip, Container, Typography } from "@mui/material";
import { useFetch } from "use-http";
import TabDetails from "./TabDetails";
import { useEffect, useState } from "react";
import Student from "../../../../interfaces/student";

const StudentDetails = () => {
  const [studentInfo, setStudentInfo] = useState<Student>();

  const { id } = useParams();

  const { get } = useFetch();

  useEffect(() => {
    async function getStudentsInfo() {
      try {
        const studentsData: any = await get(`/students/${id}`);
        setStudentInfo(studentsData);
      } catch (error) {
        console.error("Error fetching studentsInfo", error);
      }
    }
    getStudentsInfo();
  }, [get]);

  console.log("STUDENT DETAILS ABAIXO:");

  console.log(studentInfo);

  return (
    <>
      <Container>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box alignSelf={"center"} mr={2}>
              <Avatar />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h4">{studentInfo?.fullName}</Typography>
              <Typography>
                <span>Matricula: </span>
                <Chip
                  label={studentInfo?.id}
                  style={{ marginLeft: 2, height: 20 }}
                />
              </Typography>
            </Box>
          </Box>
          <Box mt={4}>
            <TabDetails student={studentInfo} />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default StudentDetails;
