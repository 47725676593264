import { createBrowserRouter } from "react-router-dom";

import Login from "../pages/Login/Login";
import BasePage from "../pages/BasePage/BasePage";
import TeachersList from "../pages/Admin/Teachers/TeachersList";
import StudentsList from "../pages/Admin/Students/List/List";
import ClassroomsList from "../pages/Admin/Classrooms/List/ClassroomsList";
import RegisterStudent from "../pages/Admin/Students/Register/RegisterStudent";
import SubjectsList from "../pages/Admin/Subjects/SubjectsList";
import EditRegister from "../pages/Admin/Students/Edit/Edit";
import StudentDetails from "../pages/Admin/Students/Details/StudentsDetails";
import ClassroomPage from "../pages/Admin/Classrooms/Details/ClassroomPage";
import CreateTeacher from "../pages/Admin/Teachers/Create/CreateTeacher";
import GradesList from "../pages/Admin/Grades/GradesList";
import UsersList from "../pages/Admin/Users/List";
import CreateUser from "../pages/Admin/Users/Create";
import LessonDetails from "../pages/Lesson/Details";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <BasePage>
        <h1>Home</h1>
      </BasePage>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/professores",
    element: (
      <BasePage>
        <TeachersList />
      </BasePage>
    ),
  },
  {
    path: "/professores/novo",
    element: (
      <BasePage>
        <CreateTeacher />
      </BasePage>
    ),
  },
  {
    path: "/alunos",
    element: (
      <BasePage>
        <StudentsList />
      </BasePage>
    ),
  },
  {
    path: "/alunos/:id",
    element: (
      <BasePage>
        <StudentDetails />
      </BasePage>
    ),
  },
  {
    path: "/turmas",
    element: (
      <BasePage>
        <ClassroomsList />
      </BasePage>
    ),
  },
  {
    path: "/turmas/:id",
    element: (
      <BasePage>
        <ClassroomPage />
      </BasePage>
    ),
  },
  {
    path: "cadastro",
    element: (
      <BasePage>
        <RegisterStudent />
      </BasePage>
    ),
  },
  {
    path: "/materias",
    element: (
      <BasePage>
        <SubjectsList />
      </BasePage>
    ),
  },
  {
    path: "/editar/:id",
    element: (
      <BasePage>
        <EditRegister />
      </BasePage>
    ),
  },
  {
    path: "/series",
    element: (
      <BasePage>
        <GradesList />
      </BasePage>
    ),
  },
  {
    path: "/usuarios",
    element: (
      <BasePage>
        <UsersList />
      </BasePage>
    ),
  },
  {
    path: "/usuarios/novo",
    element: (
      <BasePage>
        <CreateUser />
      </BasePage>
    ),
  },
  {
    path: "/aula/:id",
    element: (
      <BasePage>
        <LessonDetails />
      </BasePage>
    ),
  },
]);

export default router;
