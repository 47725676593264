import React, { useEffect, useState } from "react";
import { Controller, set, useForm } from "react-hook-form";
import * as yup from "yup";
import { Grid, Button, Avatar, Paper } from "@mui/material";
import useFetch from "use-http";
import Input from "../../../../components/Input/input";
import Select from "../../../../components/Select/Select";
import Teachers from "../../../../interfaces/teachers";
import MaskedCep from "../../../../components/MaskedCep/MaskedCep";
import MaskedCPF from "../../../../components/MaskedCPF/MaskedCPF";

// interface IFormInput {
//   nomeCompleto: string;
//   dataNascimento: string;
//   email: string;
//   cpf: string;
//   endereco: string;
//   cep: string;
// }

// const validationSchema = yup.object().shape({
//   nomeCompleto: yup.string().required("Nome completo é obrigatório"),
//   dataNascimento: yup.date().required("Data de nascimento é obrigatória"),
//   email: yup.string().email("Email inválido").required("Email é obrigatório"),
//   cpf: yup
//     .string()
//     .length(11, "CPF precisa ter 11 dígitos")
//     .required("CPF é obrigatório"),
//   endereco: yup.string().required("Endereço é obrigatório"),
//   cep: yup
//     .string()
//     .length(8, "CEP precisa ter 8 dígitos")
//     .required("CEP é obrigatório"),
// });

const CreateTeacher: React.FC = () => {
  const [avatarImage, setAvatarImage] = useState<File | null>(null);

  const { post, response } = useFetch(
    "http://localhost:3000/teachers/create",
    {}
  );

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAvatarImage(event.target.files[0]);
    }
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({});

  const [cep, setCep] = useState("");
  const { data } = useFetch(`https://viacep.com.br/ws/${cep}/json/`);

  useEffect(() => {
    if (data && data.logradouro) {
      setValue("endereco", data.logradouro);
    }
  }, [data, setValue]);

  const onSubmit = async (data: Teachers) => {
    try {
      const response = await post("/", data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper elevation={3} sx={{ width: "100%", mt: 6, mb: 3 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} p={5}>
          <>
            <Grid container display={"flex"} justifyContent={"center"}>
              <input
                accept="image/*"
                id="avatar-input"
                type="file"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
              />
              <label htmlFor="avatar-input">
                <Avatar
                  alt="Avatar"
                  src={avatarImage ? URL.createObjectURL(avatarImage) : ""}
                  sx={{ width: 80, height: 80, cursor: "pointer" }}
                />
              </label>
            </Grid>
            <Grid item xs={8}>
              <Input
                label="Nome completo"
                fullWidth
                margin="normal"
                register={register("fullName")}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="Data de nascimento"
                type="date"
                fullWidth
                margin="normal"
                register={register("dateOfBirth")}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) =>
                  value != undefined ? (
                    <Select
                      margin="normal"
                      value={value}
                      options={[
                        { label: "Masculino", value: "male" },
                        { label: "Feminino", value: "female" },
                      ]}
                      fullWidth
                      label="Gênero"
                      onChange={onChange}
                    />
                  ) : (
                    <Select
                      margin="normal"
                      value={value}
                      options={[
                        { label: "Masculino", value: "male" },
                        { label: "Feminino", value: "female" },
                      ]}
                      fullWidth
                      label="Gênero"
                      onChange={onChange}
                    />
                  )
                }
                name="gender"
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                register={register("email")}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="CPF"
                type="text"
                fullWidth
                margin="normal"
                register={register("cpf")}
                InputProps={{ inputComponent: MaskedCPF }}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="Cep"
                fullWidth
                margin="normal"
                register={register("address.zipCode")}
                InputProps={{ inputComponent: MaskedCep }}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                label="Rua"
                fullWidth
                margin="normal"
                register={register("address.street")}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                label="Número"
                fullWidth
                margin="normal"
                register={register("address.number")}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Complemento"
                fullWidth
                margin="normal"
                register={register("address.complement")}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Bairro"
                fullWidth
                margin="normal"
                register={register("address.neighborhood")}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Cidade"
                fullWidth
                margin="normal"
                register={register("address.city")}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="Estado"
                fullWidth
                margin="normal"
                register={register("address.state")}
              />
            </Grid>
          </>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Cadastrar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CreateTeacher;
