import React from "react";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardActions,
} from "@mui/material";

const Card = ({ title, content, actions, contentStyle = {}, ...rest }) => {
  return (
    <MuiCard {...rest}>
      {title && <CardHeader title={title} />}
      <CardContent style={contentStyle}>{content}</CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </MuiCard>
  );
};

export default Card;
