import { Grid, Avatar } from "@mui/material";
import { Controller } from "react-hook-form";
import Select from "../../../../components/Select/Select";
import Input from "../../../../components/Input/input";
import MaskedCep from "../../../../components/MaskedCep/MaskedCep";

const StudentForm = ({
  register,
  handleAvatarChange,
  avatarImage,
  control,
}) => {
  return (
    <>
      <Grid container display={"flex"} justifyContent={"center"}>
        <input
          accept="image/*"
          id="avatar-input"
          type="file"
          onChange={handleAvatarChange}
          style={{ display: "none" }}
        />
        <label htmlFor="avatar-input">
          <Avatar
            alt="Avatar"
            src={avatarImage ? URL.createObjectURL(avatarImage) : ""}
            sx={{ width: 80, height: 80, cursor: "pointer" }}
          />
        </label>
      </Grid>
      <Grid item xs={8}>
        <Input
          label="Nome completo"
          fullWidth
          margin="normal"
          register={register("fullName")}
        />
      </Grid>
      <Grid item xs={2}>
        <Input
          label="Data de nascimento"
          type="date"
          fullWidth
          margin="normal"
          register={register("dateOfBirth")}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) =>
            value != undefined ? (
              <Select
                margin="normal"
                value={value}
                options={[
                  { label: "Masculino", value: "male" },
                  { label: "Feminino", value: "female" },
                ]}
                fullWidth
                label="Gênero"
                onChange={onChange}
              />
            ) : (
              <Select
                margin="normal"
                value={value}
                options={[
                  { label: "Masculino", value: "male" },
                  { label: "Feminino", value: "female" },
                ]}
                fullWidth
                label="Gênero"
                onChange={onChange}
              />
            )
          }
          name="gender"
        />
      </Grid>
      <Grid item xs={2}>
        <Input
          label="Cep"
          fullWidth
          margin="normal"
          InputProps={{ inputComponent: MaskedCep }}
          register={register("address.zipCode")}
        />
      </Grid>
      <Grid item xs={5}>
        <Input
          label="Rua"
          fullWidth
          margin="normal"
          register={register("address.street")}
        />
      </Grid>
      <Grid item xs={5}>
        <Input
          label="Número"
          fullWidth
          margin="normal"
          register={register("address.number")}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label="Complemento"
          fullWidth
          margin="normal"
          register={register("address.complement")}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label="Bairro"
          fullWidth
          margin="normal"
          register={register("address.neighborhood")}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label="Cidade"
          fullWidth
          margin="normal"
          register={register("address.city")}
        />
      </Grid>
      <Grid item xs={2}>
        <Input
          label="Estado"
          fullWidth
          margin="normal"
          register={register("address.state")}
        />
      </Grid>
    </>
  );
};

export default StudentForm;
