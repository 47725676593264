import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  createTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styled from "@emotion/styled";
import { ThemeProvider } from "@emotion/react";
import { menuItens } from "./menuItens";
import { Link, useLocation } from "react-router-dom";

const drawerTheme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 280,
          background: "#910F0A",
          flexShrink: 0,
          color: "#F3FA2A",
          borderRightColor: "#910F0A",
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
  },
});

const DrawerHeader = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 24,
});

const NavAvatar = styled(Avatar)(() => ({
  borderColor: "#F3FA2A",
  color: "#F3FA2A",
  borderRadius: "8px",
  borderStyle: "solid",
  borderWidth: "1px",
  display: "flex",
  height: "40px",
  padding: "4px",
  width: "40px",
  backgroundColor: "transparent",
}));

const MenuIconButton = styled(IconButton)(() => ({
  color: "#F3FA2A",
  alignSelf: "center",
}));

const DrawerList = styled(List)(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  paddingLeft: "16px",
  paddingRight: "16px",
}));

const MenuListItem = styled(MenuItem)<{ itemselected }>(({ itemselected }) => ({
  borderRadius: 8,
  padding: "6px 16px",
  backgroundColor: itemselected === "true" ? "#FB625D" : "#910F0A",
  "&:hover": {
    backgroundColor: "#FB625D",
  },

  span: {
    color: itemselected === "true" ? "white" : "#F3FA2A",
  },

  svg: {
    color: itemselected === "true" ? "white" : "#F3FA2A",
  },
}));

const CustomDrawer = ({ isOpen }) => {
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={drawerTheme}>
      <Drawer open={isOpen} variant="persistent">
        <DrawerHeader>
          <NavAvatar>A</NavAvatar>
          <Grid
            sx={{
              margin: "0px 0px 0px 0px 16px;",
            }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Novo Milênio
            </Typography>
            <Typography variant="subtitle2" style={{ color: "white" }}>
              News
            </Typography>
          </Grid>
          <MenuIconButton onClick={handleClick}>
            {Boolean(anchorEl) ? (
              <KeyboardArrowUpIcon
                style={{
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            )}
          </MenuIconButton>
        </DrawerHeader>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Classroom 1</MenuItem>
          <MenuItem onClick={handleClose}>Classroom 2</MenuItem>
        </Menu>
        <DrawerList>
          {menuItens.map((item, index) => (
            <Link to={item.path} key={index} style={{ textDecoration: "none" }}>
              <MenuListItem
                itemselected={(pathname === item.path).toString()}
                key={index}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </MenuListItem>
            </Link>
          ))}
        </DrawerList>
      </Drawer>
    </ThemeProvider>
  );
};

export default CustomDrawer;
