import { Box, Grid, IconButton } from "@mui/material";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import User from "../../../interfaces/user";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "reactfire";
import { signInWithEmailAndPassword } from "firebase/auth";
import Snackbar from "../../../components/Snackbar/Snackbar";

const LoginForm = () => {
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [mensagemErro, setMensagemErro] = useState("");

  const { register, handleSubmit } = useForm<User>();
  const auth = useAuth();

  function errorLogin(erro) {
    let mensagemErro = "";

    switch (erro.code) {
      case "auth/user-not-found":
        mensagemErro =
          "Usuário não encontrado. Verifique se o email está correto.";
        break;
      case "auth/invalid-email":
        mensagemErro = "Email inválido. Por favor, forneça um email válido.";
        break;
      case "auth/wrong-password":
        mensagemErro = "Senha incorreta. Verifique se a senha está correta.";
        break;
      case "auth/user-disabled":
        mensagemErro = "Usuário desativado. Entre em contato com o suporte.";
        break;
      default:
        mensagemErro =
          "Ocorreu um erro ao fazer login. Tente novamente mais tarde.";
        break;
    }

    return mensagemErro;
  }

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      navigate("/");
    } catch (error: any) {
      const mensagem = errorLogin(error);
      setMensagemErro(mensagem);
      setOpenSnackbar(true);
    }
    console.log(data);
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        mt: 2,
      }}
    >
      <Snackbar
        open={openSnackbar}
        severity={"error"}
        message={mensagemErro}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setOpenSnackbar(false)}
      />
      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item>
          <Input
            label="Email"
            id="email"
            type="email"
            variant="outlined"
            fullWidth
            register={{ ...register("email") }}
            required
          />
        </Grid>
        <Grid item>
          <Input
            label="Senha"
            id="password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            register={{ ...register("password") }}
            endAdornment={
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
            required
          />
        </Grid>
        <Grid item alignSelf={"center"}>
          <Button
            variant={"contained"}
            color={"primary"}
            children={"Entrar"}
            type={"submit"}
            style={{
              borderRadius: 10,
              width: "60vh",
              height: "5vh",
              backgroundColor: "red",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
