import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import Input from "../../../../components/Input/input";
import { Controller, useForm } from "react-hook-form";
import useFetch from "use-http";
import ClassRoom from "../../../../interfaces/classRoom";
import Select from "../../../../components/Select/Select";

const RegisterClassroom = ({ open, onClose, classroom, onSubmit }) => {
  const { get, post } = useFetch();

  const [grades, setGrades] = useState([]);

  useEffect(() => {
    async function getGrades() {
      try {
        const gradesData = await get("/grades");
        setGrades(gradesData);
      } catch (error) {
        console.error("error fetching grades", error);
      }
    }
    getGrades();
  }, []);

  const { register, handleSubmit, control } = useForm<ClassRoom>();

  const onSubmitClass = async (data) => {
    try {
      const response = await post("/classrooms/create", data);
      console.log(data);
      onClose();

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={classroom ? "Editar Turma" : "Adicionar Turma"}
      children={
        <>
          <Input
            label="Nome da turma"
            fullWidth
            margin="normal"
            register={register("name")}
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) =>
              value != undefined ? (
                <Select
                  margin="normal"
                  value={value}
                  options={grades.map((grade: any) => ({
                    label: grade.name,
                    value: grade.id,
                  }))}
                  fullWidth
                  label="Série"
                  onChange={onChange}
                />
              ) : (
                <Select
                  margin="normal"
                  value={value}
                  options={grades.map((grade: any) => ({
                    label: grade.name,
                    value: grade.id,
                  }))}
                  fullWidth
                  label="Série"
                  onChange={onChange}
                />
              )
            }
            name="grade"
          />
        </>
      }
      actions={
        <>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit(onSubmitClass)} color="primary">
            Adicionar
          </Button>
        </>
      }
    />
  );
};

export default RegisterClassroom;
