import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import { useFetch } from "use-http";

const GradeFormModal = ({ open, onClose, grade, revalidator }) => {
  const [name, setName] = useState("");

  const { post } = useFetch();

  useEffect(() => {
    if (grade) {
      setName(grade.name);
    }
  }, [grade]);

  const handleSubmit = async () => {
    await post("/grades/create", { name: name });
    revalidator.revalidate();
    onClose();
  };

  const handleClose = () => {
    setName("");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={grade ? "Editar materia" : "Adicionar série"}
      children={
        <>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
        </>
      }
      actions={
        <>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {grade ? "Atualizar" : "Adicionar"}
          </Button>
        </>
      }
    />
  );
};

export default GradeFormModal;
