import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import MuiCard from "../../../../components/Card/Card";
import useFetch from "use-http";
import Student from "../../../../interfaces/student";
import GuardianForm from "./GuardianForm";
import StudentForm from "./StudentForm";
import { useSnackBar } from "../../../../hooks/SnackBarContext";

const RegisterStudent = () => {
  const [avatarImage, setAvatarImage] = useState<File | null>(null);
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAvatarImage(event.target.files[0]);
    }
  };

  const { register, handleSubmit, control, watch, setValue } =
    useForm<Student>();

  const { post } = useFetch();

  const onSubmit = async (data) => {
    try {
      const response = await post("/students/create", data);
      showSnackBar("Aluno cadastrado com sucesso!", "success");
      navigate("/alunos");
    } catch (error) {
      console.error("error post registerStudent", error);
    }
  };

  const zipCode = watch("address.zipCode");

  useEffect(() => {
    if (zipCode) {
      getAddressData(zipCode);
    }
  }, [zipCode]);

  async function getAddressData(value) {
    const addressData = await fetch(`https://viacep.com.br/ws/${value}/json/`);
    const data = await addressData.json();

    setValue("address.street", data.logradouro);
    setValue("address.neighborhood", data.bairro);
    setValue("address.city", data.localidade);
    setValue("address.state", data.uf);
  }

  return (
    <Box>
      <Typography variant="h4" mb={3}>
        Cadastro de Alunos
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MuiCard
          title={"Novo Aluno"}
          content={
            <>
              <Grid container spacing={2}>
                <StudentForm
                  register={register}
                  handleAvatarChange={handleAvatarChange}
                  avatarImage={avatarImage}
                  control={control}
                />
              </Grid>
              <hr />
              <h3>Dados do Responsável</h3>
              <Grid container spacing={2}>
                <GuardianForm register={register} />
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
            </>
          }
          actions={undefined}
        />
      </form>
    </Box>
  );
};

export default RegisterStudent;
