// pages/Admin/Teachers/TeachersList.js

import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import DataTable from "../../../components/Table/Table";
import SubjectFormModal from "./SubjectFormModal/SubjectFormModal";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "use-http";

const SubjectList = () => {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjects, setSubjects] = useState();
  const [formModalOpen, setFormModalOpen] = useState(false);

  const { get } = useFetch();

  useEffect(() => {
    async function getSubjects() {
      try {
        const subjectsData = await get("/subjects");
        setSubjects(subjectsData);
      } catch (error) {
        console.error("error fetching subjects", error);
      }
    }
    getSubjects();
  }, []);

  const revalidator = useRevalidator();

  const columns = [{ field: "name", headerName: "Nome" }];

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          setSelectedSubject(null);
          setFormModalOpen(true);
        }}
      >
        Nova Materia
      </Button>
      <DataTable rows={subjects} columns={columns} actions={undefined} />
      <SubjectFormModal
        open={formModalOpen}
        subject={selectedSubject}
        onClose={() => setFormModalOpen(false)}
        revalidator={revalidator}
      />
    </Box>
  );
};

export default SubjectList;
