import React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const DataGridTable = ({ rows = [], columns, loading }: any) => {
  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DataGridTable;
