import { Box, Paper } from "@mui/material";
import DataTable from "../../../../components/Table/Table";
import { useFetch } from "use-http";
import Button from "../../../../components/Button/Button";
import { useEffect, useState } from "react";

const UsersList = () => {
  const [users, setUsers] = useState([]);

  const { get } = useFetch();

  useEffect(() => {
    async function getUsers() {
      try {
        const usersData = await get("/users");

        setUsers(usersData);
      } catch (error) {
        console.error("error fetching users", error);
      }
    }
    getUsers();
  }, []);

  const columns = [
    { field: "name", headerName: "Nome", width: 70 },
    { field: "email", headerName: "Email", width: 70 },
    {
      field: "role",
      headerName: "Função",
      width: 70,
    },
  ];

  const rows = users?.map((user: any) => {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.roles.map((role: any) => role.description).join(", "),
    };
  });

  return (
    <>
      <Box>
        <Button variant="contained" color="primary" href="/usuarios/novo">
          Cadastrar Usuario
        </Button>
      </Box>
      <Paper>
        <h1>Usuários</h1>
        {rows != undefined && (
          <DataTable rows={rows} columns={columns} actions={null} />
        )}
      </Paper>
    </>
  );
};

export default UsersList;
