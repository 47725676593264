import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useFetch from "use-http";

function CreateClassModal() {
  const [open, setOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any>(null);

  const [schedules, setSchedules] = useState<any>([]);

  const { get, post } = useFetch();

  const { id } = useParams();

  useEffect(() => {
    get(`/schedule/${id}`).then((data) => setSchedules(data));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelectedSchedule(
      schedules.find((schedule) => schedule.id === event.target.value)
    );
  };

  const getNextDateForDay = (dayOfWeek) => {
    const today = new Date();
    const resultDate = new Date();
    resultDate.setDate(
      today.getDate() + ((dayOfWeek + 7 - today.getDay()) % 7)
    );
    return resultDate;
  };

  const formatDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const handleSubmit = async () => {
    if (selectedSchedule) {
      const nextDate = getNextDateForDay(selectedSchedule.dayOfWeek);
      await post(`/lessons/create`, {
        scheduleId: selectedSchedule.id,
        date: nextDate,
      });
      alert(`You selected the schedule on ${nextDate}.`);
    }
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Criar Aula
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Escolha um Horario</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Horarios"
            value={selectedSchedule ? selectedSchedule.id : ""}
            onChange={handleChange}
            fullWidth
          >
            {schedules?.map((schedule) => (
              <MenuItem key={schedule.id} value={schedule.id}>
                {`${schedule.subject.name} com ${
                  schedule.teacher.fullName.split(" ")[0]
                } em ${formatDate(getNextDateForDay(schedule?.dayOfWeek))} de ${
                  schedule.startTime
                } - ${schedule.endTime}`}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateClassModal;
